.payment-on-delivery {
  width: 45%;
  box-sizing: border-box;
  height: 120px;
  margin: auto;
  padding: 20px 0;
  border: 1px solid black;
  border-radius: 12px !important;
  cursor: pointer;
}

.payment-on-delivery.selected {
  background-color: #d5d5d8;
  border: none;
}


.offer-image-div {
  position: absolute;
  right: 0;
  top: -15%;
  width: 80px;
  height: 80px;
}

.offer-image {
  height: 50px;
  width: 50px;
}

@media screen and (max-width: 576px) {
  .small-font {
    font-size: 10px;
  }

  .offer-image-div {
    position: absolute;
    right: 0%;
    top: -15%;
    width: 80px;
  }

  .offer-image {
    height: 60%;
  }
}