.comparison {
    padding: 32px;
    background: #B3E2DA;
}

@media screen and (min-width: 1600px) {
    .comparison .banner-container {
        max-width: 1540px;
    }
}

.comparison .b-bottom {
    border-bottom: 2px solid #C4F1E9;
}

@media screen and (min-width: 768px) {
    .comparison .b-bottom {
        border-bottom: 3px solid #C4F1E9;
    }
}

.comparison .b-top {
    border-top: 2px solid #C4F1E9;
}

@media screen and (min-width: 768px) {
    .comparison .b-top {
        border-top: 3px solid #C4F1E9;
    }
}

.comparison .b-right {
    border-right: 3px solid #C4F1E9;
}

@media screen and (min-width: 768px) {
    .comparison .sleeping-pills:after {
        content: "";
        height: 80%;
        width: 1px;
        border: 2px solid #C4F1E9;
        background: #C4F1E9;
        position: absolute;
        top: 62px;
        right: 0;
    }
}

@media screen and (min-width: 992px) {
    .comparison .sleeping-pills:after {
        height: 75%;
        top: 80px;
    }
}

.comparison h1 {
    font-size: 30px;
    color: #217869;
    line-height: 40px;
}

@media screen and (min-width: 992px) {
    .comparison h1 {
        font-size: 44px;
        line-height: 64px;
    }
}

@media screen and (min-width: 1200px) {
    .comparison h1 {
        font-size: 50px;
        line-height: 56px;
    }
}

@media screen and (min-width: 1600px) {
    .comparison h1 {
        font-size: 73px;
        line-height: 100px;
    }
}

.comparison p {
    font-size: 14px;
    line-height: 19px;
    color: #217869;
}

@media screen and (min-width: 992px) {
    .comparison p {
        font-size: 18px;
        line-height: 24px;
    }
}

.comparison .versus:before {
    content: "";
    width: 100%;
    position: absolute;
    left: -90%;
    top: 18px;
    border: 1px solid #217869;
    background: #fff;
}

.comparison .versus:after {
    content: "";
    width: 100%;
    position: absolute;
    right: -90%;
    top: 18px;
    border: 1px solid #217869;
    background: #fff;
}

@media screen and (min-width: 768px) {
    .comparison .versus {
        position: absolute;
        top: 24px;
        left: 95%;
        margin: 0 !important;
    }

    .comparison .versus:after {
        content: none;
    }

    .comparison .versus:before {
        content: none;
    }
}

@media screen and (min-width: 1600px) {
    .comparison .versus {
        left: 94%;
    }
}