.left-div {
    padding-left: 8% !important;
}

.left-div>h2 {
    color: #fff;
    font-size: 70px;
}

.right-div {
    text-align: center;
}

.price {
    color: #fff;
}

.bottom-price {
    text-align: left;
    margin-left: 20px;
}

.btn-div {
    width: 200px;
}

.buy-btn {
    padding: 10 20px !important;
}

.bottle {
    width: 80%;
    transform: rotate(8deg);
}

.bottom-price span:first-child::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #f37e25;
    transform: rotate(165deg);
}


@media only screen and (max-width: 576px) {
    .left-div>h2 {
        font-size: 20px;
    }

    .bottle {
        margin-top: 10%;
        width: 80%;
        transform: rotate(8deg);
    }

    .price {
        font-size: 18px !important;

        margin-right: 20px;
    }

    .buy-btn {
        font-size: 8px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .left-div>h2 {
        font-size: 40px;
    }

    .bottle {
        margin-top: 20px;
        width: 90%;
        transform: rotate(8deg);
    }

    .btn-div {
        width: 150px;
        margin-left: 20px;
    }
}

.buy-now-desktop-banner {
    width: 100%;
    padding: 14px;
    position: sticky;
    bottom: 0;
    background: #6BD8C9;
}

.buy-now-desktop-banner .bottle-img {
    width: 130px;
    position: absolute;
    bottom: 20px;
    left: 20%;
    transform: translateX(-50%);
    top: -40px;
}

.home-page-bottle-img {
    position: absolute;
    left: 38%;
    transform: translateX(-0%);
    top: -100px;
    width: 350px;
}

@media only screen and (max-width: 576px) {
    .bottle-img {
        position: absolute;
        left: 15% !important;
        top: -20px !important;
        width: 100px !important;
    }

    .money-back-garuntee-div {
        width: 70% !important;
        margin-left: 28%;
    }

}

@media screen and (min-width: 992px) {
    .buy-now-desktop-banner .bottle-img {
        bottom: -17px;
    }
}

@media screen and (min-width: 1200px) {
    .buy-now-desktop-banner .bottle-img {
        width: 158px;
    }
}

.buy-now-desktop-banner .offer-img {
    width: 300px;
}

@media (min-width: 1440px) {
    .buy-now-desktop-banner .offer-img {
        width: 384px;
    }
}

@media screen and (min-width: 992px) {
    .buy-now-desktop-banner .price {
        font-size: 30px;
        line-height: 34px;
    }
}

@media screen and (min-width: 1400px) {
    .buy-now-desktop-banner .price {
        font-size: 33px;
        line-height: 40px;
    }
}

.buy-now-desktop-banner .mrp {
    color: #8c8c8c;
}

.buy-now-desktop-banner .mrp:after {
    content: "";
    width: 44px;
    transform: rotate(348deg);
    position: absolute;
    left: 70px;
    top: 13px;
    border: 2px solid rgb(240, 78, 33);
    background: rgb(240, 78, 33);
    opacity: 0.6;
}

@media screen and (min-width: 992px) {
    .buy-now-desktop-banner .mrp:after {
        width: 70px;
        left: 48px;
        top: 16px;
    }
}

@media screen and (min-width: 1200px) {
    .buy-now-desktop-banner .mrp:after {
        left: 73px;
        top: 20px;
    }
}

.buy-now-desktop-banner .offer-price {
    color: #fff;
}

.buy-now-desktop-banner .buy-btn {
    background-image: url("../../../public/assets/buy-btn-background.png");
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    text-align: center;
    padding: 9px 28px;
    background-position: center;
    border-radius: 40px;
    width: 90%;
}

@media screen and (min-width: 992px) {
    .buy-now-desktop-banner .buy-btn {
        font-size: 18px;
        padding: 8px 32px;
    }
}

@media screen and (min-width: 1600px) {
    .buy-now-desktop-banner .buy-btn {
        font-size: 24px;
    }
}

.buy-now-desktop-banner {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.buy-now-desktop-banner.visible {
    opacity: 1;
}