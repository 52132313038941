.sleepzzz-navbar {
    /* background-image: url('../../../public/assets/menu-background.png'); */
    /* background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */
    background: #140730;
    color: #fff;
}

.right-margin {
    margin-right: 20px;
}

.left-margin {
    margin-left: 15px;
}

.decoration-text-none {
    text-decoration: none;
    color: #fff;
}

.brand-img {
    filter: invert(1) !important;
    width: 160px;
}

.first-navbar {
    background: #140730;
}

.dropdown-menu {
    width: 320px;
    right: 0;
    transform: translateX(15%);
}

.dropdown-item-fontsize {
    font-size: 12px;
}

.login-body {
    width: 95%;
    margin: auto;
}

.verify-btn {
    width: 95%;
    background-image: url("../../../public/assets/buy-btn-background.png");
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    text-align: center;
    padding: 9px 28px;
    background-position: center;
    border-radius: 40px;
    border: none;
    margin-top: 20px;
}

.password-switch-text {
    color: #F4861E;
    text-decoration: underline;
    cursor: pointer;
}

.custom-dropdown-item {
    font-size: 13px;
    background: #fff !important
}

.custom-dropdown-item:hover {
    background-color: rgb(241, 235, 235) !important;
}

.custom-border-radius {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.pointer-cursor {
    cursor: pointer;
}

.color-white {
    color: #fff !important;
}

.login-btn {
    background: #fff;
    border-radius: 18px;
    border: none;
    outline: none;
    padding: 6px 20px;
}

.sleepzzz-brand-name {
    font-size: 32px !important;
    margin-left: 25px;
}

@media screen and (max-width: 576px) {
    .brand-img {
        width: 90px;
    }

    .login-btn {
        font-size: 10px;
        background: #fff;
        border-radius: 14px;
        border: none;
        outline: none;
        padding: 6px 15px;
        margin-right: 10px;
    }

    .dropdown-menu {
        width: 300px;
        right: 0;
    }
}

.checkout-btn {
    border: none;
    outline: none;
    width: 95%;
    margin-left: 2%;
    border-radius: 40px;
    background-image: url('../../../public/assets/buy-btn-background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
    .brand-img {
        width: 90px;
    }

    .login-btn {
        font-size: 10px;
        background: #fff;
        border-radius: 14px;
        border: none;
        outline: none;
        padding: 6px 15px;
        margin-right: 10px;
    }

    .dropdown-menu {
        width: 300px;
        right: 0;
    }
}

header {
    overflow: visible;
  }
  header .uplife-navbar {
    background: #140730 !important;
    color: #fff;
  }
  header .uplife-navbar .navbar-toggler {
    padding: 0 !important;
    border: none !important;
  }
  header .uplife-navbar .navbar-toggler .navbar-toggler-icon {
    background-image: url("../../../public/assets/menu-icon.png");
  }
  @media screen and (min-width: 992px) {
    header .uplife-navbar .uplife-brand {
      position: absolute;
      left: 50%;
    }
  }
  header .uplife-navbar .brand-img {
    filter: invert(1) !important;
    width: 100px;
  }
  @media screen and (min-width: 992px) {
    header .uplife-navbar .brand-img {
      width: 80%;
    }
  }
  header .uplife-navbar .cart-icon {
    width: 20px;
  }
  header .uplife-navbar .profile-icon {
    width: 20px;
  }
  @media screen and (min-width: 992px) {
    header .uplife-navbar .profile-icon {
      width: 26px;
    }
  }
  header .uplife-navbar .cart {
    margin-left: 18%;
  }
  @media screen and (min-width: 375px) {
    header .uplife-navbar .cart {
      margin-left: 38%;
    }
  }
  @media screen and (min-width: 768px) {
    header .uplife-navbar .cart {
      margin-left: 60%;
    }
  }
  header .uplife-navbar .uplife-links {
    text-align: right;
  }
  header .uplife-navbar .uplife-links .nav-link {
    color: #fff;
  }
  header .uplife-navbar .uplife-links .nav-link:hover {
    text-decoration: underline;
  }
  header .uplife-navbar a {
    color: #fff;
  }
  header .sleepzzz-navbar {
    background-image: url("../../../public/assets/menu-background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  @media screen and (min-width: 992px) {
    header .sleepzzz-navbar {
      padding: 14px 30px;
    }
  }
  header .sleepzzz-navbar .navbar-brand {
    color: #fff;
  }
  header .sleepzzz-navbar .navbar-brand:hover {
    color: #fff;
  }
  header .sleepzzz-navbar .navbar-toggler {
    padding: 0 !important;
    border: none !important;
  }
  header .sleepzzz-navbar .navbar-toggler .navbar-toggler-icon {
    background-image: url("../../../public/assets/grid-menu-icon.png");
    background-size: auto;
  }
  header .sleepzzz-navbar .nav-item .nav-link {
    color: #fff !important;
  }
  header .login-btn {
    background: #fff;
    color: #140730 !important;
    font-family: "karu-medium";
    font-size: 16px;
    border-radius: 47px;
    padding: 4px 16px;
  }
  header .login-btn:hover {
    color: #140730 !important;
  }
  header .sleepzz-logo {
    font-family: "Karu-medium";
    font-size: 40px;
    line-height: 55px;
    color: #fff !important;
  }
  header .sleepzz-logo:hover {
    color: #fff !important;
  }
  header .dropdown-menu {
    left: auto !important;
    border-radius: 15px !important;
    right: 10px !important;
    top: 45px !important;
    padding: 14px 0 14px 0px !important;
  }
  @media screen and (min-width: 992px) {
    header .dropdown-menu {
      right: 20px !important;
      top: 76px !important;
      padding: 14px 0 14px 0px !important;
    }
  }
  header .dropdown-menu .dropdown-item {
    position: relative;
    color: #1E1E1E !important;
    margin-bottom: 10px;
    padding-right: 50px !important;
  }
  header .dropdown-menu .dropdown-item span {
    font-family: "karu";
    font-size: 15px;
  }
  header .dropdown-menu .dropdown-item small {
    color: #848484;
    font-size: 12px;
  }
  header .dropdown-menu .dropdown-item:hover {
    background: #f1f4f7 !important;
    cursor: pointer;
  }
  header .dropdown-menu .dropdown-item:hover:first-child {
    cursor: default !important;
    background: #fff !important;
  }
  header .dropdown-menu .dropdown-item .user-icon {
    width: 45px !important;
    position: relative;
    top: -19px;
    left: -11px;
  }
  header .dropdown-menu .dropdown-item .menu-user-icon {
    width: 20px !important;
  }
  header .dropdown-menu .dropdown-item .menu-icon {
    width: 14px;
    margin-right: 8px;
    position: relative;
    top: -10px;
  }
  header .dropdown-menu .dropdown-item i {
    position: absolute;
    right: 20px;
    top: 16px;
    color: #1e1e1e;
  }
  header .dropdown-menu .dropdown-item:active {
    background: #f1f4f7 !important;
  }
  