.small-imgs-main-box {
    width: 20%;
}

.small-img-div {
    width: 100% !important;
}

.big-img-main-box {
    margin-top: 30px;
    width: 80%;
}

.bottle-imgs-main-box {
    width: 100%;
    height: 500px;
}

.horizontal-line {
    border: 1px solid white;
}

.main-bottle-image {
    width: 100% !important;
}

.desktop-products-section .products-slider .small_img {
    width: 100px;
    margin: 10px 0;
}

.slider-container {
    width: 98%;
    padding: 2%;
}


.white-text {
    color: #fff;
}

.white-text:hover {
    color: #fff !important;
}

.product-price span:first-child::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #f37e25;
    transform: rotate(180deg);
}

.card-selected {
    background-color: #e7d4ff !important;
    border: 2px solid #6bd8c9 !important;
    box-sizing: border-box;
    width: 96% !important;
    height: 140px;
    position: relative;
}

.recomended-badge {
    width: 100%;
    border-radius: 0 0 4px 4px !important;
    background: #EE5F62;
    color: #fff;
    border-radius: 30px;
    padding: 4px 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
}

.first-image {
    width: 90px;
    margin-left: 16px;
}

.desktop-products-section .back-bg-color {
    background: #6BD8C9;
    border-radius: 100px 0 0 100px;
}

.back-bg-color {
    background: #6BD8C9;
    border-radius: 100px 0 0 100px;
}

.cursor-pointer {
    cursor: pointer;
}

.selected-text {
    position: absolute;
    bottom: 0px;
    left: 60%;
    transform: translateX(-60%);
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    background: #6bd8c9;
    width: 100%;
    margin-top: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.add-to-cart-btn {
    background-image: url("../../../public/assets/buy-btn-background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 10px 20px !important;
    border-radius: 50px !important;
    width: 100%;
}

.offer-percent {
    font-weight: 600;
    font-size: 15px;
    color: #4aa78e;
}

.light-font-weight {
    font-weight: 500;
    margin-top: -10px;
}

.mobile-slider-container {
    width: 100%;
    margin: auto;
    /* border: 1px solid red; */
}

.bg-color {
    background: #6BD8C9; 
}

@media screen and (min-width: 1200px) {
    .desktop-products-section .products-slider .small_img {
        margin: 20px 0;
    }
}


@media screen and (min-width: 1200px) {
    .desktop-products-section .products-slider .main_img {
        width: 100% !important;
    }
}