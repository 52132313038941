.address-form {
  padding: 14px 22px;
}

@media screen and (min-width: 992px) {
  .address-form {
    padding: 60px 22px;
  }
}

@media screen and (min-width: 1200px) {
  .address-form .banner-container {
    max-width: 1300px !important;
  }
}

@media screen and (min-width: 1600px) {
  .address-form {
    padding: 100px 22px;
  }

  .address-form .banner-container {
    max-width: 1540px;
  }
}

.address-form h1 {
  font-size: 25px;
  line-height: 27px;
  color: #fff;
}

.address-form .form-group {
  margin-bottom: 0 !important;
}

@media screen and (min-width: 768px) {
  .address-form .form-group {
    margin-bottom: 8px !important;
  }
}

@media screen and (min-width: 992px) {
  .address-form .form-group {
    margin-bottom: 14px !important;
  }
}

.address-form label {
  font-size: 12px;
  line-height: 12px;
  color: #fff;
}

@media screen and (min-width: 992px) {
  .address-form label {
    font-size: 14px;
    line-height: 14px;
  }
}

.address-form input {
  background: #F4F0FF;
}

.address-form ::-moz-placeholder {
  font-size: 12px;
  line-height: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.address-form ::placeholder {
  font-size: 12px;
  line-height: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 992px) {
  .address-form ::-moz-placeholder {
    font-size: 14px;
    line-height: 14px;
  }

  .address-form ::placeholder {
    font-size: 14px;
    line-height: 14px;
  }
}

.address-form small a:hover {
  cursor: pointer;
}

.address-form .buy-btn {
  background-image: url("../../../public/assets/buy-btn-background.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  text-align: center;
  padding: 9px 28px;
  background-position: center;
  border-radius: 40px;
}

@media screen and (min-width: 375px) {
  .address-form .buy-btn {
    padding: 12px 40px;
  }
}

@media screen and (min-width: 768px) {
  .address-form .buy-btn {
    font-size: 18px;
  }

  .shipping-address-box {
    width: '80%' !important;
  }
}

@media screen and (min-width: 1200px) {
  .address-form .buy-btn {
    font-size: 20px;
  }
}

@media screen and (min-width: 1600px) {
  .address-form .buy-btn {
    font-size: 24px;
  }
}

.address-form .address {
  border: 1px solid #140730;
  border-radius: 13px;
  display: block;
  margin-bottom: 16px;
  cursor: pointer;
}

.address-form .address:last-child {
  margin-bottom: 0px;
}

.address-form .address a {
  color: #1E1E1E;
}

.address-form .address p {
  margin-bottom: 16px !important;
}


.saved-address-main-box {
  color: black;
  background-color: #fff;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
}

.saved-address-main-box {
  overflow: auto;
  height: 80vh;
}

.saved-address-main-box::-webkit-scrollbar {
  width: 5px;
}

.saved-address-main-box::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.saved-address-main-box::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.saved-address-main-box::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.shipping-address-box {
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid black;
  line-height: normal;
}

.add-btn {
  border: none;
  outline: none;
  padding: 8px 25px;
  border-radius: 18px;
  margin: 15px 10px 0 0;
  font-weight: bold;
  background-color: #FF9800;
}