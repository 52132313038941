@media screen and (min-width: 1200px) {
  .effects-section {
    padding: 51px 110px 68px 125px;
  }
}

@media screen and (min-width: 1600px) {
  .effects-section {
    padding: 51px 110px 68px 125px;
  }
}

@media screen and (min-width: 1200px) {
  .effects-section .eyes {
    width: 159px;
    margin-bottom: 42px;
  }
}

.effects-section h2 {
  color: #fff;
  margin-bottom: 23px !important;
}

@media screen and (min-width: 1200px) {
  .effects-section h2 {
    margin-bottom: 65px !important;
  }
}

.effects-section .card {
  background: none;
}

.effects-section .card:first-child {
  margin-right: 6px;
}

.effects-section .card .card-body {
  border-radius: 70px;
  padding: 32px 11px 23px !important;
}

.effects-section .card .card-body img {
  margin-bottom: 10px;
}

.effects-section .card .card-body h5 {
  color: #564082;
  margin-bottom: 5px;
}

.effects-section .green-card {
  background: #b3e2da;
}

.effects-section .purple-card {
  background: #d4d6f4;
}

.effects-section .pink-card {
  background: #fbeae2;
}

.effects-section {
  padding: 36px 10px 22px 13px;
  box-sizing: border-box;
}


.effects-section .eyes {
  width: 136px;
  margin-bottom: 24px;
}

.effect-heading {
  width: 75%;
  margin: auto;
  font-size: 20px;
  color: #fff;
}

.effect-cards-main-div {
  display: grid;
  grid-template-columns: repeat(3, 25%);
  gap: 3%;
  justify-content: center;
}

.effect-cards-second-div {
  display: grid;
  grid-template-columns: repeat(2, 40%);
  gap: 4%;
  justify-content: center;
  margin-top: 20px;
}

.effect-description {
  color: #fff;
}

.cards-row {
  padding: 11px;
}

.cards {
  text-align: center;
  padding: 0px !important;
}

.cards img {
  width: 147px;
  padding: 5px;
}

@media screen and (min-width: 375px) {
  .cards img {
    width: 172px;
    padding: 5px;
  }
}

@media screen and (min-width: 425px) {
  .cards img {
    width: 190px;
    padding: 5px;
  }
}

@media screen and (min-width: 992px) {
  .cards img {
    width: 236px;
  }
}

@media screen and (min-width: 1200px) {
  .cards img {
    width: 280px;
  }
}

.cards .special-card {
  width: 94%;
}

.cards h5 {
  color: #fff;
  margin-top: 30px !important;
}

@media screen and (min-width: 1200px) {
  .cards h5 {
    margin-top: 60px !important;
  }
}


@media only screen and (max-width: 576px) {
  .drive-img {
    height: 100px;
    width: 50%;
  }

  .effect-description {
    font-size: 15px !important;
  }
}