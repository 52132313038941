* {
  box-sizing: border-box;
  font-family: "Open Sans";
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Open Sans', sans-serif;
  height: 100vh;
  background-image: url("../public/assets/features-background.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.slider-container {
  width: 98%;
  padding: 2%;
}

.shipping-address-box {
  border-radius: 10px;
  cursor: pointer;
}

.selected-box {
  background-color: #f1e6fe;
  cursor: pointer;
  border: 1px solid black;
  margin-right: 20px !important;
}

.not-selected-box {
  cursor: pointer;
  border: 1px solid black;
}


.scroll-to-top-btn {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background-color: #f37e25;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 100;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll-to-top-btn.visible {
  opacity: 1;
}

.history h5 {
  font-family: "karu-medium";
}
.history h6,
.history h4,
.history a {
  font-family: "karu";
  margin-bottom: 4px !important;
}
.history a {
  color: #1E1E1E;
}
.history .order-history h6 {
  font-size: 14px;
}
.history .order-history h5 {
  font-size: 16px;
}
.history .order-history p {
  font-size: 12px;
}
@media screen and (min-width: 768px) {
  .history .order-history {
    width: 560px;
  }
}
@media screen and (min-width: 992px) {
  .history .order-history {
    width: 660px;
  }
}
.history .order-history .bg-success {
  background-color: #03B469 !important;
  border-radius: 0px 0px 13px 13px;
}
.history .order-history .bg-danger {
  background: #720303 !important;
  border-radius: 0px 0px 13px 13px;
}
.history .purple-bg {
  background: #513593;
  border-radius: 0px 0px 13px 13px;
}
.history .blue-bg {
  background: #03B4B4;
  border-radius: 0px 0px 13px 13px;
}
.history .track-no {
  text-decoration: underline;
}
.history .track-no:hover {
  color: #fff;
}
.history .checked {
  color: rgb(248, 209, 92);
}
.history .hide {
  display: none;
}
.history .dark-orange {
  color: #B74B1B;
}
.history .dark-blue {
  color: #1D0048;
}
.history .link-primary {
  color: cornflowerblue !important;
}
.history .secondary-font {
  font-family: Mikela;
}
.history .light-orange {
  color: #E59120;
}
.history .light-green {
  color: #40C936;
}
.history .bg-lightPink {
  background-color: rgba(183, 75, 27, 0.0784313725);
}
.history .pe-cursor {
  cursor: pointer;
}
.history .fw-bold {
  font-weight: 800 !important;
}
.history .fw-800 {
  font-weight: 800;
}
.history .fw-700 {
  font-weight: 700;
}
.history .fw-600 {
  font-weight: 600;
}
.history .fw-500 {
  font-weight: 500;
}
.history .border-radius {
  border-radius: 23px;
}
.history .border-radius1 {
  border-radius: 5px;
}
.history .border-radius2 {
  border-radius: 10px;
}
.history .border-radius3 {
  border-radius: 13px;
}
@media screen and (min-width: 768px) {
  .history .borderRadius-productCard {
    border-radius: 25px;
  }
}
.history .fixed {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  z-index: 2;
  margin-bottom: 60% !important;
}
