
.how-it-works {
    background-size: cover;
    background-repeat: no-repeat;
    padding: 30px 22px;
  }
  
  @media screen and (min-width: 1600px) {
    .how-it-works .banner-container {
      max-width: 1540px;
    }
  }
  
  .how-it-works .pill-image {
    width: 115px;
    margin-bottom: 13px;
  }
  
  .how-it-works h1 {
    color: #fff;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    margin-bottom: 23px !important;
  }
  
  @media screen and (min-width: 992px) {
    .how-it-works h1 {
      font-size: 50px;
      line-height: 68px;
      margin-bottom: 40px !important;
    }
  }
  
  @media screen and (min-width: 1600px) {
    .how-it-works h1 {
      font-size: 75px;
      line-height: 103px;
      margin-bottom: 55px !important;
    }
  }
  
  .how-it-works .green-card {
    background: #B3E2DA;
  }
  
  .how-it-works .blue-card {
    background: #DEE1F2;
  }
  
  .how-it-works .pink-card {
    background: #FCE3EE;
  }
  
  .how-it-works .purple-card {
    background: #D4D6F4;
  }
  
  .how-it-works .ingredient-card {
    margin-bottom: 9px !important;
  }
  
  @media screen and (min-width: 992px) {
    .how-it-works .ingredient-card {
      margin-bottom: 22px !important;
    }
  }
  
  .how-it-works .card {
    background: none;
  }
  
  .how-it-works .card .card-body {
    border-radius: 22px;
    padding: 20px 14px;
  }
  
  .how-it-works .card .card-body h2 {
    color: #564082;
  }
  
  @media screen and (min-width: 992px) {
    .how-it-works .card .card-body h2 {
      font-size: 35px;
      line-height: 47px;
    }
  }
  
  @media screen and (min-width: 768px) {
    .how-it-works .card .card-body .affron-img {
      width: 300px;
    }
  }
  
  @media screen and (min-width: 992px) {
    .how-it-works .card .card-body .affron-img {
      width: 260px;
    }
  }
  
  @media screen and (min-width: 1200px) {
    .how-it-works .card .card-body .affron-img {
      width: 190px;
    }
  }
  
  @media screen and (min-width: 768px) {
    .how-it-works .card .card-body .ing-img {
      width: 114px;
    }
  }
  
  @media screen and (min-width: 1200px) {
    .how-it-works .card .card-body .ing-img {
      width: 140px;
    }
  }
  
  .how-it-works .card .card-body ul {
    list-style: none;
    padding: 0;
  }
  
  @media screen and (min-width: 768px) {
    .how-it-works .card .card-body ul {
      width: 170px;
      margin: 0 auto;
      text-align: left;
    }
  }
  
  @media screen and (min-width: 992px) {
    .how-it-works .card .card-body ul {
      width: 240px;
      margin: 0 auto;
      text-align: left;
    }
  }
  
  @media screen and (min-width: 1200px) {
    .how-it-works .card .card-body ul {
      width: 270px;
      margin: 0 auto;
      text-align: left;
    }
  }
  
  .how-it-works .card .card-body ul li {
    font-size: 12px;
    line-height: 16px;
    color: #564082;
    border-bottom: 1px solid #564082;
  }
  
  .how-it-works .card .card-body ul li:last-child {
    border: none;
  }
  
  @media screen and (min-width: 768px) {
    .how-it-works .card .card-body ul li {
      margin-bottom: 3px;
      padding-bottom: 3px;
    }
  }
  
  @media screen and (min-width: 992px) {
    .how-it-works .card .card-body ul li {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 4px;
      padding-bottom: 4px;
    }
  }
  
  .how-it-works .card .card-body p {
    color: #564082;
  }
  
  @media screen and (min-width: 1600px) {
    .how-it-works .card .card-body p {
      font-size: 20px !important;
      line-height: 25px !important;
    }
  }
  
  .how-it-works .card .card-body .read-more {
    color: #000;
    font-size: 12px;
    line-height: 16px;
  }
  
  .how-it-works .card .card-body .read-more-icon {
    width: 9px;
    margin-bottom: 1px;
  }