.first-banner-block {
    padding: 60px 0px !important;
    color: #fff;
}

.first-banner-block>h1 {
    font-weight: 700 !important;
    font-size: 50px;
    line-height: 70px;
}

.second-banner-block {
    padding: 60px 0 !important;
    color: #fff;
}

.second-banner-block>h1 {
    margin-left: 100px;
    font-weight: 700 !important;
    font-size: 50px;
    line-height: 70px;
}

@media only screen and (max-width: 576px) {
    .first-banner-block>h1 {
        width: 70%;
        margin: auto;
        font-size: 25px !important;
        line-height: 50px !important;
        text-align: left !important;
        padding: -20px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .first-banner-block {
        margin: 0 !important;
    }

    .first-banner-block>h1 {
        width: 100%;
        font-size: 28px !important;
        line-height: 50px !important;
        text-align: left !important;
        padding-left: 20px;
    }

    .second-banner-block>h1 {
        width: 100%;
        font-size: 28px !important;
        line-height: 50px !important;
        text-align: left !important;
        padding-left: 20px;
        margin-left: 0px;
    }
}