.footer .uplife-img {
  filter: invert(1) !important;
  width: 100px;
}

.footer {
  width: 100%;
  padding: 20px 0 20px;
  background-color: #190941 !important;
}

.star-img {
  width: 50px;
  margin-top: 15px;
}

@media screen and (max-width: 576px) {
  .star-img {
    width: 40px;
    height: 40px;
    margin-top: 15px;
  }

  .footer-text {
    font-size: 12px;
  }
}

@media screen and (min-width: 992px) {
  .footer .uplife-img {
    width: 140px;
  }
}