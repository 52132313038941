.buy-now-section {
    background: #564082;
    padding: 24px 24px 30px;
    color: #fff;
}

.home-bottle-img {
    position: absolute;
    width: 380px;
    left: 35%;
    top: -40%;
}

.bottom-price span:first-child::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #f37e25;
    transform: rotate(165deg);
}

.buy-btn {
    background-image: url("../../../public/assets/buy-btn-background.png");
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff !important;
    text-align: center;
    padding: 15px 28px !important;
    background-position: center;
    border-radius: 40px !important;
    font-size: 20px !important;
}

.product-features-section {
    width: 40%;
}

.product-features-section>div>h5 {
    font-size: 18px;
}

.award-images{
    width: 100px;
}

@media only screen and (max-width: 576px) {
    .product-description-section {
        font-size: 13px;
    }

    .product-description-section-p {
        font-size: 10px;
    }

    .home-bottle-img {
        position: absolute;
        width: 185px;
        left: 50%;
        top: -40%;
        right: 0%;
    }

    .product-features-section {
        width: 100% !important;
    }

    .product-features-section>div>h5 {
        font-size: 10px;
    }

    .award-heading{
        font-size: 18px;
    }

    .award-images{
        width: 150%;
    }
    .bottom-price {
        font-size: 12px !important;
    }
    .price {
        font-size: 20px !important;
        margin-top: 10px;
        margin-left: 20px;
    }

    .buy-btn {
        padding: 10px !important;
        font-size: 12px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .product-description-section {
        font-size: 18px;
    }

    .product-description-section-p {
        font-size: 12px;
    }

    .home-bottle-img {
        position: absolute;
        width: 220px;
        left: 35%;
        top: -40%;
        right: 0%;
    }

    .product-features-section {
        width: 40% !important;
        margin-top: -20px !important;
    }

    .product-features-section>div>h5 {
        font-size: 10px;
    }

    .price {
        font-size: 20px !important;
        margin-top: 10px;
    }

    .bottom-price {
        font-size: 12px !important;
    }

    .buy-btn {
        padding: 10px !important;
        font-size: 12px !important;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1400px) {
    .buy-btn {
        padding: 10px !important;
        font-size: 14px !important;
    }

    .price {
        font-size: 30px !important;
        margin-top: 10px;
    }
}

.buy-now-section {
    background: #564082;
    padding: 24px 24px 30px;
    color: #fff;
  }

  .buy-now-section .card {
    border-radius: 21px !important;
  }

  .buy-now-section .card .card-body {
    padding: 8px !important;
  }

  @media screen and (min-width: 768px) {
    .buy-now-section .card .card-body {
      padding: 14px 14px 26px !important;
    }
  }
  .buy-now-section .card .card-body h2 {
    color: #311858;
  }
  @media screen and (min-width: 1200px) {
    .buy-now-section {
      padding: 40px 95px;
    }
  }
  @media screen and (min-width: 1600px) {
    .buy-now-section {
      padding: 20px 240px;
    }
    .buy-now-section .banner-container {
      max-width: 1540px;
    }
  }
  .buy-now-section h3 {
    margin-bottom: 10px !important;
  }
  @media screen and (min-width: 1200px) {
    .buy-now-section h3 {
      margin-bottom: 6px !important;
    }
  }
  @media screen and (min-width: 1200px) {
    .buy-now-section h3 {
      margin-bottom: 13px !important;
    }
  }
  .buy-now-section .bottle-img {
    position: absolute;
    top: -60px;
    width: 122px;
    right: 12px;
  }
  @media screen and (min-width: 768px) {
    .buy-now-section .bottle-img {
      position: relative;
      top: 0;
      width: 260px;
    }
  }
  @media screen and (min-width: 992px) {
    .buy-now-section .bottle-img {
      position: absolute;
      top: -85px;
      width: 212px;
      right: 18px;
    }
  }
  @media screen and (min-width: 1200px) {
    .buy-now-section .bottle-img {
      width: 231px;
    }
  }
  @media screen and (min-width: 1400px) {
    .buy-now-section .bottle-img {
      width: 240px;
    }
  }
  @media screen and (min-width: 1600px) {
    .buy-now-section .bottle-img {
      top: -103px;
      width: 272px;
      right: 83px;
    }
  }
 
  .buy-now-section h5 {
    font-weight: 400;
  }
  .buy-now-section .award-images {
    width: 60px;
  }
  @media screen and (min-width: 425px) {
    .buy-now-section .award-images {
      width: 70px;
    }
  }
  @media screen and (min-width: 768px) {
    .buy-now-section .award-images {
      width: 92px;
    }
  }
  @media screen and (min-width: 1600px) {
    .buy-now-section .award-images {
      width: 120px;
    }
  }
  .buy-now-section .price {
    font-size: 26px;
  }
  @media screen and (min-width: 375px) {
    .buy-now-section .price {
      font-size: 32px;
    }
  }
  @media screen and (min-width: 768px) {
    .buy-now-section .price {
      font-size: 37px;
    }
  }
  @media screen and (min-width: 992px) {
    .buy-now-section .price {
      font-size: 34px;
    }
  }

  @media screen and (max-width: 576px) {
    .price {
        font-size: 15px !important;
    }
  }
  @media screen and (min-width: 1400px) {
    .buy-now-section .price {
      font-size: 37px;
    }
  }
  .buy-now-section .mrp {
    color: #dfc8ff;
  }
  .buy-now-section .mrp:after {
    content: "";
    width: 56px;
    transform: rotate(348deg);
    position: absolute;
    left: 21px;
    top: 14px;
    border: 2px solid rgb(240, 78, 33);
    background: rgb(240, 78, 33);
    opacity: 0.8;
  }
  @media screen and (min-width: 375px) {
    .buy-now-section .mrp:after {
      width: 70px;
    }
  }
  @media screen and (min-width: 768px) {
    .buy-now-section .mrp:after {
      width: 80px;
    }
  }
  @media screen and (min-width: 1200px) {
    .buy-now-section .mrp:after {
      top: 34px;
    }
  }
  
  .buy-now-section .buy-btn {
    background-image: url("../../../public/assets/buy-btn-background.png");
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    text-align: center;
    padding: 9px 28px;
    background-position: center;
    border-radius: 40px;
  }
  @media screen and (min-width: 375px) {
    .buy-now-section .buy-btn {
      padding: 12px 40px;
    }
  }
  @media screen and (min-width: 768px) {
    .buy-now-section .buy-btn {
      font-size: 18px;
    }
  }
  @media screen and (min-width: 1200px) {
    .buy-now-section .buy-btn {
      font-size: 20px;
    }
  }
  @media screen and (min-width: 1600px) {
    .buy-now-section .buy-btn {
      font-size: 24px;
    }
  }
  