.benifits-section .benifits-list {
    list-style: none;
}

.benefit-heading {
    color: #b3e2da;
    font-weight: bold;
    margin: 30px;
}

.benifits-section .benifits-list li {
    color: #fff;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 22px;
}

.benifits-list li {
    animation: slideInRight 0.5s ease-in-out forwards;
}

.benifits-list li {
    opacity: 0;
}

.benifits-list {
    display: flex;
    flex-direction: column;
    margin-left: 80px;
}

.benifits-list li {
    animation: slideInRight 0.5s ease-in-out forwards;
    opacity: 0;
}

@keyframes slideInRight {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@media screen and (min-width: 992px) {
    .benifits-section .benifits-list li {
        font-size: 25px;
        line-height: 33px;
    }
}

@media screen and (min-width: 1600px) {
    .benifits-section .benifits-list li {
        font-size: 31px;
        line-height: 41px;
    }
}

.benifits-section .benifits-list li:before {
    content: "";
    height: 20px;
    width: 20px;
    background: url(../../..//public/assets/check-icon.png) no-repeat;
    background-size: contain;
    position: absolute;
    left: -24px;
    margin-top: 4px;
}

@media screen and (min-width: 992px) {
    .benifits-section .benifits-list li:before {
        width: 33px;
        height: 20px;
        margin-top: 8px;
        left: -40px;
    }
}

@media screen and (min-width: 1600px) {
    .benifits-section .benifits-list li:before {
        width: 44px;
        height: 25px;
        margin-top: 14px;
        left: -52px;
    }
}

@media screen and (min-width: 1596px) {
    .benifits-section .sleeping-img img {
        width: 100% !important;
    }
}

/* 
@media only screen and (max-width: 576px) {
    .benefit-points {
        text-align: left;
        border: 1px solid white;
        
    }
    .benifits-list{
        width: 100%;
    }

    @keyframes slideInRight {
        0% {
            transform: translateX(-100%);
            opacity: 0;
        }
    
        100% {
            transform: translateX(-120%);
            opacity: 1;
        }
    }
} */

@media only screen and (max-width: 576px) {
    .benefit-points {
        text-align: left;
    }

    .benifits-list {
        width: auto;
        padding-left: 0;
        list-style-position: inside;
       
    }

    .benifits-list li {
        text-align: left;
        margin-left: 0;
    }

    @keyframes slideInRight {
        0% {
            transform: translateX(-100%);
            opacity: 0;
        }

        100% {
            transform: translateX(-30%);
            opacity: 1;
        }
    }
}