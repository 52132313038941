
.faq-section {
    overflow: hidden;
    color: #fff;
  }
  
  .faq-section h1 {
    color: #23066D;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
  }
  
  @media screen and (min-width: 992px) {
    .faq-section h1 {
      font-size: 50px;
      line-height: 68px;
    }
  }
  
  @media screen and (min-width: 1600px) {
    .faq-section h1 {
      font-size: 75px;
      line-height: 103px;
    }
  }
  
  .faq-section .faq-row .bg-color {
    padding: 16px;
  }
  
  .faq-section .faq-row .bg-color:first-child {
    padding: 6px;
  }
  
  .faq-section .faq-row .bg-color:nth-child(odd) {
    background: #B3E2DA;
  }
  
  .faq-section .faq-row .bg-color:nth-child(odd):before {
    content: "";
    width: 100%;
    height: 100%;
    background: #B3E2DA;
    position: absolute;
    right: 50%;
    top: 0;
    z-index: -1;
  }
  
  .faq-section .faq-row .bg-color:nth-child(odd):after {
    content: "";
    width: 100%;
    height: 100%;
    background: #B3E2DA;
    position: absolute;
    left: 50%;
    top: 0;
    z-index: -1;
  }
  
  .faq-section .faq-row .bg-color:nth-child(even) {
    background: #B6B9FF;
  }
  
  .faq-section .faq-row .bg-color:nth-child(even):before {
    content: "";
    width: 100%;
    height: 100%;
    background: #B6B9FF;
    position: absolute;
    right: 50%;
    top: 0;
    z-index: -1;
  }
  
  .faq-section .faq-row .bg-color:nth-child(even):after {
    content: "";
    width: 100%;
    height: 100%;
    background: #B6B9FF;
    position: absolute;
    left: 50%;
    top: 0;
    z-index: -1;
  }
  
  .bg-heading-green {
    background: #b3e2da;
    border-top: 1px solid whitesmoke;
  }
  
.bg-green {
    background: #b3e2da;
    padding: 16px;
  }
  
  .bg-blue {
    background: #b6b9ff;
    padding: 16px;
  }